import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlAuthGuard } from '@xpo-ltl/ngx-auth';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SymEnsembleGuideComponent } from './landing-page/sym-ensemble-guide/sym-ensemble-guide.component';
import { SymRuleGuideComponent } from './landing-page/sym-rule-guide/sym-rule-guide.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { PathMissingRedirectGuard } from './shared/guards/path-missing-redirect.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LANDING_PAGE,
    component: LandingPageComponent,
    canActivate: [XpoAuthenticationGuard, XpoLtlAuthGuard],
    children: [
      {
        path: AppRoutes.SYMPHONY_RULE_GUIDE,
        component: SymRuleGuideComponent,
        canActivate: [XpoAuthenticationGuard, XpoLtlAuthGuard],
      },
      {
        path: AppRoutes.SYMPHONY_ENSEMBLE_GUIDE,
        component: SymEnsembleGuideComponent,
        canActivate: [XpoAuthenticationGuard, XpoLtlAuthGuard],
      },
    ],
  },
  {
    path: AppRoutes.SYMPHONY_RULES,
    loadChildren: () => import('./symphony-rules/symphony-rules-demo.module').then((m) => m.SymphonyRulesDemoModule),
    canLoad: [XpoAuthenticationGuard, XpoLtlAuthGuard],
  },
  {
    path: `${AppRoutes.SYMPHONY_ENSEMBLES}/:app`,
    loadChildren: () =>
      import('./symphony-ensembles/symphony-ensembles-demo.module').then((m) => m.SymphonyEnsemblesDemoModule),
    canLoad: [XpoAuthenticationGuard, XpoLtlAuthGuard, PathMissingRedirectGuard],
  },
  {
    path: AppRoutes.SYMPHONY_RADAR,
    loadChildren: () => import('./symphony-radar/symphony-radar-demo.module').then((m) => m.SymphonyRadarDemoModule),
    canActivate: [XpoAuthenticationGuard, XpoLtlAuthGuard],
  },
  {
    path: AppRoutes.CHANGE_FEED,
    loadChildren: () => import('./change-feed/change-feed.module').then((m) => m.ChangeFeedModule),
    canLoad: [XpoAuthenticationGuard, XpoLtlAuthGuard],
  },
  {
    path: AppRoutes.CHANGE_FEED_CHAT,
    loadChildren: () => import('./change-feed-chat/change-feed-chat.module').then((m) => m.ChangeFeedChatModule),
    canLoad: [XpoAuthenticationGuard, XpoLtlAuthGuard],
  },
  {
    path: '',
    redirectTo: AppRoutes.LANDING_PAGE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
