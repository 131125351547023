import { Component } from '@angular/core';

@Component({
  selector: 'app-sym-ensemble-guide',
  templateUrl: './sym-ensemble-guide.component.html',
  styleUrls: ['./sym-ensemble-guide.component.scss'],
})
export class SymEnsembleGuideComponent {
  constructor() {}
}
