import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core';
import { SharedModule } from '../shared/shared.module';
import { LandingPageComponent } from './landing-page.component';
import { SymEnsembleGuideComponent } from './sym-ensemble-guide/sym-ensemble-guide.component';
import { SymRuleGuideComponent } from './sym-rule-guide/sym-rule-guide.component';

@NgModule({
  declarations: [LandingPageComponent, SymRuleGuideComponent, SymEnsembleGuideComponent],
  imports: [CommonModule, SharedModule, XpoCardModule, RouterModule],
})
export class LandingPageModule {}
