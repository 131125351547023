import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';

@NgModule({
  declarations: [ActionBarComponent, ActionBarLeftContent, ActionBarCenterContent, ActionBarRightContent],
  imports: [BrowserModule, MatIconModule],
  exports: [ActionBarComponent, ActionBarLeftContent, ActionBarCenterContent, ActionBarRightContent],
})
export class SharedModule {}
