import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { EMPTY, Observable } from 'rxjs';
import { MaintIndicatorEnum } from '../enums/maint-indicator.enum';
import { RegionInfo } from '../models/region-info.model';
import { MaintConfigService } from './maint-config.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceInterceptorService implements HttpInterceptor {
  private maintIndicator: MaintIndicatorEnum;
  private maintAdmins: string[];
  private currentUserEmail: string;

  constructor(
    private router: Router,
    private maintConfigService: MaintConfigService,
    private authService: XpoAuthenticationService
  ) {
    this.maintConfigService.getRegionInfo().subscribe((regionInfo: RegionInfo) => {
      this.maintIndicator = regionInfo.maintIndicator;
      this.maintAdmins = regionInfo.maintAdmins;
      this.currentUserEmail = this.authService.getUser().profile.email.toLowerCase();
      // this.currentUserEmail = 'mitch.lu@xpo.com';
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.maintIndicator === MaintIndicatorEnum.UNAVAILABLE) {
      return this.routeToMaint();
    } else if (this.maintIndicator === MaintIndicatorEnum.RESTRICTED) {
      const isUserAdmin = this.maintAdmins.find((email: string) => {
        return email.toLowerCase() === this.currentUserEmail;
      });
      if (isUserAdmin) {
        return next.handle(req);
      } else {
        return this.routeToMaint();
      }
    }
    return next.handle(req);
  }

  private routeToMaint(): Observable<never> {
    this.router.navigate(['maintenance']);
    return EMPTY;
  }
}
