export enum AppRoutes {
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  SYMPHONY_RADAR = 'symphony-radar',
  SYMPHONY_BEACONS = 'beacons',
  SYMPHONY_RULES = 'symphony-rules',
  SYMPHONY_ENSEMBLES = 'symphony-ensembles',
  LANDING_PAGE = 'landing-page',
  SYMPHONY_RULE_GUIDE = 'symphony-rule-guide',
  SYMPHONY_ENSEMBLE_GUIDE = 'symphony-ensemble-guide',
  X_FLOW = 'xflow',
  X_FLOW_QUEUE = 'xflow/queue',
  CHANGE_FEED = 'change-feed',
  CHANGE_FEED_CHAT = 'change-feed-chat',
}
