import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegionInfo } from '../models/region-info.model';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  regionInfo: RegionInfo;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.regionInfo = this.route.snapshot.data.regionInfo;
  }
}
