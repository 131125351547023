import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RegionInfo } from '../models/region-info.model';
import { MaintConfigService } from './maint-config.service';

@Injectable({
  providedIn: 'root',
})
export class MaintResolverService implements Resolve<RegionInfo> {
  constructor(private maintConfigService: MaintConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RegionInfo> {
    return this.maintConfigService.getRegionInfo().pipe(take(1));
  }
}
