import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sym-rule-guide',
  templateUrl: './sym-rule-guide.component.html',
  styleUrls: ['./sym-rule-guide.component.scss'],
})
export class SymRuleGuideComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
