import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatDialogModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core';
import { MAINT_CONFIG } from './maint-token';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceConfig } from './models/maintenance-config.model';
import { MaintResolverService } from './services/maint-resolver.service';
import { MaintenanceInterceptorService } from './services/maintenance-interceptor.service';

const routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    resolve: {
      regionInfo: MaintResolverService,
    },
  },
];

@NgModule({
  declarations: [MaintenanceComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    XpoButtonModule,
    MatDialogModule,
    XpoFeedbackModule,
    AngularFireModule,
  ],
  exports: [RouterModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptorService, multi: true }],
})
export class XpoMaintenanceModule {
  static initialize(maintenanceConfig: MaintenanceConfig): ModuleWithProviders {
    return {
      ngModule: XpoMaintenanceModule,
      providers: [{ provide: MAINT_CONFIG, useValue: maintenanceConfig }],
    };
  }
}
