import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoShell, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { SymApps, XpoLtlGlobalEnsembleFilterService } from '@xpo-ltl/symphony-ensemble-ui';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader/dynamic-script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
  title: string;
  build: string;
  routes: XpoShellRoute[];
  filters: XpoFilter[];

  @ViewChild(XpoShell, { static: false })
  private shell: XpoShell;
  selectedRegion: string;
  private selectedInterval: number;

  constructor(
    private configManagerService: ConfigManagerService,
    private authConfigService: XpoLtlAuthConfigLoaderService,
    private dialog: MatDialog,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private authService: XpoLtlAuthenticationService,
    private globalFilterService: XpoLtlGlobalEnsembleFilterService
  ) {
    this.routes = [
      {
        label: 'Guides',
        path: null,
        children: [
          { label: 'Symphony Rules', path: `${AppRoutes.LANDING_PAGE}/${AppRoutes.SYMPHONY_RULE_GUIDE}` },
          { label: 'Symphony Ensembles', path: `${AppRoutes.LANDING_PAGE}/${AppRoutes.SYMPHONY_ENSEMBLE_GUIDE}` },
        ],
      },
      {
        label: 'Radar / Beacon',
        path: AppRoutes.SYMPHONY_RADAR,
      },
      {
        label: 'Symphony Rules',
        path: AppRoutes.SYMPHONY_RULES,
      },
      {
        label: 'Symphony Ensembles',
        path: null,
        children: [
          {
            label: 'Demo',
            path: `${AppRoutes.SYMPHONY_ENSEMBLES}/${SymApps.DEMO_APP}`,
          },
          {
            label: 'Pickup & Delivery',
            path: `${AppRoutes.SYMPHONY_ENSEMBLES}/${SymApps.PND_APP}`,
          },
          {
            label: 'Dynamic Pricing',
            path: `${AppRoutes.SYMPHONY_ENSEMBLES}/${SymApps.DYN_APP}`,
          },
          {
            label: 'Linehaul Operations',
            path: `${AppRoutes.SYMPHONY_ENSEMBLES}/${SymApps.LNH_APP}`,
          },
          {
            label: 'Pickup Request',
            path: `${AppRoutes.SYMPHONY_ENSEMBLES}/${SymApps.PUR_APP}`,
          },
        ],
      },
      {
        label: 'Beacon Notification',
        path: AppRoutes.CHANGE_FEED,
      },
      {
        label: 'Change Feed Chat',
        path: AppRoutes.CHANGE_FEED_CHAT,
      },
    ];

    this.filters = globalFilterService.setupGlobalFilters();

    /** Shell setup */
    this.title = 'DevOps Demo';
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.selectedRegion = configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.globalFilterService.regionFilter = this.selectedRegion;
    this.globalFilterService.publishRegionChange(this.selectedRegion);
  }

  private initializeDynatrace(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${_toLower(this.configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${_toLower(region)}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        _invoke(window['dtrum'], 'identifyUser', !_isEmpty(u.emailAddress) ? u.emailAddress : u.userId);
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            setIdentity(user);
          });
      }
    });
  }

  ngAfterViewInit(): void {
    this.globalFilterService.isSideBarOpen$().subscribe((value) => (this.shell.isDrawerOpen = value));
  }

  onGlobalFilterChange($event): void {
    if (!this.selectedRegion) {
      this.selectedRegion = $event.region;
      this.configManagerService.setSetting('region', $event.region);
    } else if ($event.region && $event.region !== this.selectedRegion) {
      this.authService.logout();
      this.authService.initAuthSetup$($event.region).subscribe((info) => {
        this.authConfigService.apiUrl = info.apiUrl;
        this.configManagerService.setSetting('apiUrl', info.apiUrl);
        this.globalFilterService.publishRegionChange($event.region);
      });
      this.selectedRegion = $event.region;
      this.configManagerService.setSetting('region', $event.region);
    }
    if ($event.refreshInterval && $event.refreshInterval !== this.selectedInterval) {
      this.globalFilterService.publishRefreshIntervalChange($event.refreshInterval);
      this.selectedInterval = $event.refreshInterval;
    }
  }
}
