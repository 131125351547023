import { Component, OnInit } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlGlobalEnsembleFilterService } from '@xpo-ltl/symphony-ensemble-ui';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private globalFilterService: XpoLtlGlobalEnsembleFilterService
  ) {}

  ngOnInit(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    // This call triggers filter changed event.
    this.globalFilterService.regionFilter = region;
    this.globalFilterService.publishRegionChange(region);
    this.authService.getSupportedRegions$().subscribe((regions) => console.log(regions));
  }
}
