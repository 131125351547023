import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { MAINT_CONFIG } from '../maint-token';
import { RegionInfo } from '../models/region-info.model';

@Injectable({
  providedIn: 'root',
})
export class MaintConfigService {
  private appName: string;
  private _region$: BehaviorSubject<string>;

  constructor(
    @Inject(MAINT_CONFIG) private maintenanceConfig,
    private fs: AngularFirestore,
    private configManagerService: ConfigManagerService
  ) {
    this.appName = this.maintenanceConfig.appName;
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.REGION);
    this._region$ = new BehaviorSubject<string>(region);
  }

  get region$(): Observable<string> {
    return this._region$.asObservable();
  }

  publishRegionChange(region: string): void {
    this._region$.next(region);
  }

  getRegionInfo(): Observable<RegionInfo> {
    return this._region$.pipe(
      mergeMap((region: string) => {
        return this.fs
          .collection('appKeyConfigs')
          .doc(this.appName)
          .collection('regions')
          .doc<RegionInfo>(region)
          .valueChanges();
      })
    );
  }
}
